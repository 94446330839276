.hover-underline-animation {
    display: inline-block;
    position: relative;
    margin: 0;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 45%;
    width: 10%;
    height: 0.1em;
    background-color: $cf-red-color;
    opacity: 0;
    transition: transform 300ms;
    margin: 0;
}

.hover-underline-animation:hover::after {
    transform: scaleX(10);
    opacity: 255;
}