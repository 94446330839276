/*
 * _includes.scss is the stylesheet for all of the cfdistillery ad-hoc includes
  */

/*
 * Image
  */
.image {
  width: 31%;

  figcaption {
    text-align: center;
    font-style: italic;
  }

  @include screen-width-query($on-mobile) {
    width: 100%;
  }
}
.image.left {
  float: left;
  margin-right: calc(#{$spacing-unit} / 2); ;
}
.image.right {
  float: right;
  margin-left: calc(#{$spacing-unit} / 2); ;
}
.image.wide {
  width: 50%;
}
.image.xwide {
  width: 66%;
}
.image.full {
  width: 100%;
}
.image.center {
  margin: auto;
  float: none !important;
}
.image.mobile { // Hide image on mobile
  @include screen-width-query($on-mobile) {
    display: none;
  }
}
  

.announcement {
  float: left;
  margin: 0;
  padding: 0;
  
  img {
    height: 700px;
  }
  @include screen-width-query($on-mobile) {
    display: block;
    float: none;
    width: 100%;
    img {
      height: auto;
    }
  }
}
/*
 * News block for homepage
  */
.news-block {
  overflow: hidden;
  background-position: center;
  /*border-bottom: 3px solid $grey-color;*/
  .news-block-wrapper {
    width: 40%;
    background-color: rgba(0, 0, 0,0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 700px;
    box-sizing: border-box;
    padding: $spacing-unit;

    p {
      color: white;
      line-height: 1.8;
      text-shadow: black 1px 1px;
      @include relative-font-size(1.1);
    }
    h1 {
      color: white;
      line-height: 1.8;
      text-shadow: black 1px 1px;
      @include relative-font-size(2);
    }

    @include screen-width-query($on-tablet) {
      width: 100%;
    }
  }
  .style-button {
    margin: 0;
  }
}
.news-block.left .news-block-wrapper {
  align-items: flex-end;
  h1, p, .style-button {
    text-align: right;
  }
}

.news-block.right .news-block-wrapper {
  align-items: flex-start;
  float: right;
  h1, p, .style-button {
    text-align: left;
  }
}

/*
 * Standard button style
  */
.style-button {
  border: 7px solid $cf-red-color;
  background-color: rgba(0,0,0,.4);
  color: white;
  display: inline-block;
  font-family: $title-font-family;
  padding: calc(#{$spacing-unit} / 3); ;
  margin-bottom: 15px;
  margin-right: 15px;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background-color: white;
    transition: background-color 100ms ease-in-out;
    text-decoration: none;
  }
  
}
.style-button.invert {
  color: black;
  background-color: transparent;
}

.style-button.fixwidth {
  width: 80%;
}

.center-h {
  width: 100%;
  text-align: center;
}


.batch-card {
  background-color: rgb(54, 54, 54);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  
  .batch-card-sub {
    display: inline-block;
    padding: 5px 10px;
    h4, p {
      color: rgb(236,201,117);
      
      width: 100%;
      margin: 0;
      text-align: center;
      line-height: 1.5;
      vertical-align: middle;
    }
    p {
      @include relative-font-size(1.4);
      text-transform: uppercase;
    }
    h4 {
      @include relative-font-size(0.9);
    }
    
  }
  h2 {
    color: rgb(236,201,117);
    
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}