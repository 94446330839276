.post-header {
    margin-bottom: $spacing-unit;
    width: 100%;
    height: 360px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    .post-title {
      @include relative-font-size(4.5);
      letter-spacing: -1px;
      margin-bottom: 0;
      text-align: center;
      vertical-align: middle;
      color: $off-white-color;
      text-shadow: black 2px 1px;
  
      @include screen-width-query($on-mobile) {
        @include relative-font-size(3);
      }
    }
  
    
    @include screen-width-query($on-mobile) {
      height: 250px;
    }
}
.post-banner-century {
  @include relative-font-size(4.5);
  background-color: rgb(186, 12, 47);
  text-shadow: 1px 1px 0 rgb(165, 140, 62),
              -1px -1px -0 rgb(165, 140, 62),
              -1px 1px 0 rgb(165, 140, 62),
              1px -1px -0 rgb(165, 140, 62);
}

.post-banner-heritage {
  @include relative-font-size(4.5);
  background-color: rgb(165, 140, 62);
  text-shadow: 1px 1px 0 rgb(186, 12, 47),
              -1px -1px -0 rgb(186, 12, 47),
              -1px 1px 0 rgb(186, 12, 47),
              1px -1px -0 rgb(186, 12, 47);
}

.post-content {
    margin-bottom: $spacing-unit;
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
    width: 50%;
    margin: auto;

    @include screen-width-query($on-tablet) {
        width: initial;
    }

    p {
        line-height: 1.8;
    }

    h2 {
        @include relative-font-size(2);

        @include screen-width-query($on-tablet) {
        @include relative-font-size(1.75);
        }
    }

    h3 {
        @include relative-font-size(1.625);

        @include screen-width-query($on-tablet) {
        @include relative-font-size(1.375);
        }
    }

    h4 {
        @include relative-font-size(1.25);

        @include screen-width-query($on-tablet) {
        @include relative-font-size(1.125);
        }
    }
}