/**
 * Site header & logo
 */

.navbar {
  // Site header is fixed at 90px tall
  position: fixed;
  width: 100%;
  background-color: $off-white-color;

  .site-title-wrapper {
    // Wrapper for site title image
    display: inline-block;
    height: 90px;

    .site-title img {
      max-height: 70px;
      padding-top: 10px;
      padding-left: 100px;

      @include screen-width-query($on-mobile) {
        height: 100%;
      }
      @include screen-width-query($on-tablet) {
        padding-left: $spacing-unit;
      }
    }
  }
}
  
  /**
   * Site navigation (inside .navbar)
   * Includes functionality for dynamically changing navbar with screen width
   */
  .site-nav {
    float: right;
    height: 90px;
    line-height: 90px;
    padding-right: $spacing-unit;
    z-index: 10000;
    .nav-trigger {
      // Nav trigger to detect 
      display: none;
    }
  
    .menu-icon {
      display: none;
    }
    .social-link {
      text-decoration: none;
    }
    .social-icon {
      fill: $off-black-color;
      width: 16px;
      height: 16px;
      display: inline-block;
    }
    .social-link:not(:last-child) {
      margin-right: 10px;
    }
  
    .page-link {
      color: $off-black-color;
      font-family: $title-font-family;
      @include relative-font-size(1.25);
      text-transform: uppercase;
      text-decoration: none;
      line-height: $base-line-height;
  
      // Gaps between nav items, but not on the last one
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  
    .nav-dropdown-parent {
      display: inline-block;
      
      .nav-dropdown {
        display: none;
        position: absolute;
        left: auto;
        box-sizing: border-box;
        background-color: $off-white-color;
        padding: $spacing-unit/4 $spacing-unit/2 $spacing-unit/2 $spacing-unit/2;
        white-space: pre;
        a::after {
          content: '\a'
        }
      }
      &:hover .nav-dropdown {
        display: block;
        
      }
    }
  
    @include screen-width-query($on-tablet) {
      text-align: right;
  
      // nav-trigger is the actual hamburger menu button
      label[for="nav-trigger"] {
        display: block;
        position: absolute;
        float: right;
        width: 36px;
        height: 36px;
        top: 50%; // Vertical center icon
        right: 30px;
        margin-top: calc(#{36px} / -2); ; // Account for padding
        z-index: 2;
        cursor: pointer;
      }
  
      .menu-icon {
        display: block;
        float: right;
        line-height: 0;
        text-align: center;
  
        > svg {
          fill: $off-black-color;
        }
      }
  
      .social-icon {
        width: 40px;
        height: 40px;
      }
  
      input ~ .trigger {
        clear: both;
        display: none;
      }
  
      // Box is checked when hamburger is clicked
      // Shows the fullscreen fixed menu
      input:checked ~ .trigger {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        padding-left: 0;
        margin-left: 0;
        top: 90px;
        background-color: $off-white-color;
        .social-link:last-of-type {
          padding-right: 20px;
        }
      }
  
      .page-link {
        display: block;
        padding: 5px 20px;
        padding: 5px 20px;
        font-size: 40px;
        line-height: 1;
  
        &:not(:last-child) {
          margin-right: 0;
        }
      }
  
      .nav-dropdown-parent {
        .nav-dropdown {
          display: block;
          position: relative;
          margin: 0;
          padding: 5px 0 5px 0;
          
          a {
            font-size: $title-font-size;
            padding: 5px 0 5px 0;
          }
        }
      }
    }
  }
  
  .hscroll-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    iframe {
      display: inline-block;
    }
  }