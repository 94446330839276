.ctct-popup-form {
    h2 {
      font-family: $title-font-family !important;
    }
    p:not(.ctct-gdpr-text) {
      font-family: $base-font-family !important;
    }
  }
  
  .post-subtitle {
    @include relative-font-size(2);
    text-align: center;
  }
  
  .post-subsubtitle {
    text-align: center;
  }