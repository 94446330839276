/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $off-black-color;
  background-color: $off-white-color;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure {
  margin-bottom: calc(#{$spacing-unit} / 2); ;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $caption-font-size;
  font-family: $caption-font-family;
}

/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $title-font-weight;
  font-family: $title-font-family;
  text-transform: uppercase;
  
}

/**
 * Links
 */
a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $off-black-color;
    text-decoration: underline;
  }
}

/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color;
  padding-left: calc(#{$spacing-unit} / 2); ;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}