@charset "utf-8";

// This file is intended to hold all of the style variables one may
// want to change. Defaults are defined here as well.

// ***
// FONT SETTINGS
// All fonts should be defined here.
// ***

// base-font is intended for paragraphs, snippets of text, etc.
$base-font-family: 'Lato', Roboto, Helvetica, Arial, sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;

// title-font is intended for headers, navbar items, and buttons
// Everything that draws attention to itself should be title-font.
$title-font-family: 'Montserrat', Roboto, Helvetica, Arial, sans-serif;
$title-font-size: 18px;
$title-font-weight: 700;

// caption-font is used for captions under images.
$caption-font-family: $base-font-family;
$caption-font-size: $base-font-size * 0.875;

// ***
// COLORS
// All colors should be defined here.
// ***

// Colors specific to the brand.
$cf-red-color: #ba0c2f; // Primary brand color
$cf-green-color: #284734; // Secondary brand color

$off-black-color: #111; // Color of text. Not pure black but very very dark grey
$off-white-color: #fdfdfd; // Page background. Not pure white
$link-color: #2a7ae2; // Color of links inline in paragraphs

$grey-color: #828282;

$overlay-dark: rgba(0,0,0,0.9);
$overlay-light: rgba(0,0,0,0.6);

// ***
// LAYOUT & SPACING
// All layout units should be defined here.
// ***

// Default line height
$base-line-height: 1.5;

// Spacing unit used in lots of contexts.
$spacing-unit: 30px; 

// Content area widths
$content-width: 800px;
// The point at which content becomes full-width and navbar collapses
$on-tablet: 1200px;
// Detects phones. All iPhones have a logical pixel width less than 500px
$on-mobile: 500px;

// Detect screen width
@mixin screen-width-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials
@import
  "cfdistillery/base", // Reset fonts, styles
  "cfdistillery/default/footer", // Footer on every page
  "cfdistillery/default/navbar", // Header and navbar on every page
  "cfdistillery/default/page-content", // <main>
  "cfdistillery/post", // Layout & style for corn-to-whiskey posts
  "cfdistillery/ctct", // Changes to ctct form styles
  "cfdistillery/corn-to-whiskey", // Corn to whiskey landing page
  "cfdistillery/age-gate", // Age gate layout and style
  "cfdistillery/products", // Product page
  "cfdistillery/includes", // Ad-hoc includes (buttons, news-blocks, etc)
  "cfdistillery/animation", // Animations (only unnecessary things)
  "cfdistillery/shopify" // Shopify style overrides
;
