.c2w-gallery figure {
    display: inline-block;
    margin: calc(#{$spacing-unit} / 3); ;
    width: 30%;
    @include screen-width-query($on-mobile) {
      width: 100%;
      margin: 0;
      margin-bottom: calc(#{$spacing-unit} / 2); ;
    }
  }

  .c2w-card-wrapper:hover {
    text-decoration: none;
  }
  
  .c2w-card {
    border: 1px solid $grey-color;
    padding: $spacing-unit;
    margin-bottom: calc(#{$spacing-unit} / 2);
    box-sizing: border-box;
    color: black;
    p {
      margin: 0;
      margin-top: 15px;
    }
    h1 {
      margin-bottom: 0;
      display: inline;
    }
    span {
      margin-right: 15px;
      white-space: nowrap;
    }
    .c2w-label-image {
      float: left;
      width: 100px;
      margin-right: $spacing-unit;
    }
    
    .tag {
      border: 3px solid rgb(186, 12, 47);
      border-radius: 5px;
      padding: 5px;
      vertical-align: text-bottom;
      margin-left: 15px;
      text-transform: uppercase;
      font-family: $title-font-family;
    }

    .tag-century {
      border: 3px solid rgb(186, 12, 47);
    }

    .tag-heritage {
      border: 3px solid rgb(165, 140, 62);
    }
  }
  .c2w-card:hover {
    font-weight: bolder;
  }
  
  .c2w-posts {
    height: 800px;
    overflow: scroll;
  }
  
  #c2w-searchbox {
    width: 100%;
    padding: 5px;
    text-transform: uppercase;
    font-family: $title-font-family;
    margin-bottom: 10px;
    box-sizing: border-box;
    @include relative-font-size(1.25);
  }