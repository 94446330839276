.age-gate.background {
    display: none;
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: $overlay-dark;
    .modal {
      box-sizing: border-box;
      width: 50%;
      transform: translate(50%, 50%);
      padding: $spacing-unit;
      background-color: white;
      border: 7px solid $cf-red-color;
  
      button a {
        color: black;
        text-decoration: none;
      }
      img {
        max-width: 300px;
        width:100%;
      }
      button {
        margin-top: 15px;
      }
      a {
        margin-top: $spacing-unit;
      }
    }
    @include screen-width-query($on-tablet) {
      .modal {
        margin: $spacing-unit;
        width: 90vw;
        margin: 5vw;
        transform: none;
        button {
          padding: 20px;
        }
        @include relative-font-size(1.25)
      }
      
    }
}