.site-footer {
    .mobile-footer-wrapper {
      display: none;
    }
    background-color: $cf-red-color;
    span {
      font-family: $title-font-family;
    }
  
    .site-footer-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      &>div {
        padding: calc(#{$spacing-unit} / 2); ;
      }
      p, a {
        color: $off-white-color;
        text-decoration: underline;
      }
      a {
        text-decoration: underline;
      }
    }
  
    .footer-left {
      display: flex;
      justify-content: space-between;
      div {
        display: inline-block;
        padding-right: calc(#{$spacing-unit} / 2); ;
      }
    }
  
    .footer-logo {
      img {
        margin: auto;
        display: block;
        padding: 15px;
        width: 70%;
        max-width: 150px;
      }
    }
  
    .footer-copyright {
      width: 100%;
      background-color: $grey-color;
      
      p {
        margin: 0;
        padding: 7px;
        text-align: center;
        font-family: $title-font-family;
      }
    }
  @include screen-width-query($on-mobile) {
    .footer-link {
      margin: 10px;
    }
    .footer-left {
      flex-direction: column;
      a {
        @include relative-font-size(1.2);
      }
    }
  }
  @include screen-width-query($on-tablet) {
    .footer-left a {
      @include relative-font-size(1.5);
    }
  }
}